<template>
  <div>
    <b-alert
      :show="countdown"
      dismissible
      variant="success"
      @dismissed="countdown = 0"
      @dismiss-count-down="close_alert"
      class="show_alert"
    >
      {{ dismessage }}
    </b-alert>
    <b-container fluid class="content store-table">
      <el-tabs lazy v-model="tab_name" @tab-click="tab_clicked">
        <el-tab-pane :label="$t('Table List')" name="table">
          <el-row :gutter="10">
            <el-col :span="2" class="text-right mt-1">
              {{ $t('Store')}}
            </el-col>
            <el-col :span="4">
              <el-select
                v-model="cur_LocationID"
                size="mini"
                @change="change_store"
              >
                <el-option
                  v-for="st in storelist"
                  :key="st.LocationID"
                  :label="st.LocationName"
                  :value="st.LocationID"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col v-if="cur_floorIDn" :span="2" class="text-right mt-1">
              {{ $t('Area') }}
            </el-col>
            <el-col v-if="cur_floorIDn" :span="4">
              <el-select
                v-model="cur_floorIDn"
                size="mini"
                @change="change_floor2"
              >
                <el-option
                  v-for="fl in floorlist"
                  :key="fl.IDn"
                  :label="fl.Name"
                  :value="fl.IDn"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <b-card v-if="tablelist">
            <el-row :gutter="10">
              <el-col :span="24">
                <el-table
                  stripe
                  class="table-responsive table"
                  header-row-class-name="thead-light"
                  style="width: 100%;"
                  :data="tablelist"
                >
                  <!-- <el-table-column :label="$t('Store')" prop="LcoationID">
                    <template slot-scope="scope">
                      {{ get_store_name(scope.row) }}
                      <div v-if="scope.row.IDn">
                        {{ get_store_name(scope.row) }}
                      </div>
                      <div v-else>
                        <el-select
                          v-model="scope.row.LocationID"
                          size="mini"
                          @change="change_table_add_store(scope.row)"
                        >
                          <el-option
                            v-for="st in storelist"
                            :key="st.LocationID"
                            :label="st.LocationName"
                            :value="st.LocationID"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Area')">
                    <template slot-scope="scope">
                      {{ get_floor_name(scope.row) }}
                      <div v-if="scope.row.IDn">
                        {{ get_floor_name(scope.row) }}
                      </div>
                      <div v-else>
                        <el-select
                          v-if="table_add_floorlist"
                          v-model="scope.row.Floor"
                          size="mini"
                        >
                          <el-option
                            v-for="fl in table_add_floorlist"
                            :key="fl.IDn"
                            :label="fl.Name"
                            :value="fl.IDn"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </template>
                  </el-table-column> -->
                  <el-table-column :label="$t('Name')" prop="Name">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.Name" @change="update_table2(scope.row)" size="mini"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Shape')" prop="TableType">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.TableType"
                        @change="update_table2(scope.row)"
                        size="mini"
                      >
                        <el-option
                          v-for="tp in table_shapes"
                          :key="tp"
                          :label="tp"
                          :value="tp"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Type')" prop="TableType2">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.TableType2"
                        @change="update_table2(scope.row)"
                        size="mini"
                      >
                        <el-option
                          v-for="tp in table_types"
                          :key="tp"
                          :label="tp"
                          :value="tp"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Size') + '(WH|R)'">
                    <template slot-scope="scope">
                      <el-row v-if="scope.row.TableType=='Square'">
                        <el-col :span="12">
                          <el-input v-model="scope.row.Width" type="number" min="30" max="400" step="1" @change="update_table2(scope.row)" size="mini"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <el-input v-model="scope.row.Height" type="number" min="30" max="400" step="1" @change="update_table2(scope.row)" size="mini"></el-input>
                        </el-col>
                      </el-row>
                      <el-row v-if="scope.row.TableType=='Round'">
                        <el-col>
                          <el-input v-model="scope.row.Height" type="number" min="30" max="400" step="1" @change="update_table2(scope.row)" size="mini"></el-input>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Location') + '(XY)'" prop="TableType2">
                    <template slot-scope="scope">
                      <el-row>
                        <el-col :span="12">
                          <el-input v-model="scope.row.OffsetX" type="number" min="0" max="1024" step="1" @change="update_table2(scope.row)" size="mini"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <el-input v-model="scope.row.OffsetY" type="number" min="0" max="640" step="1" @change="update_table2(scope.row)" size="mini"></el-input>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Number Of')" prop="Guest">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.Guest" @change="update_table2(scope.row)" size="mini"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Menu')" prop="MenuIDn">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.MenuIDn"
                        @change="update_table2(scope.row)"
                        size="mini"
                      >
                        <el-option label="none" :value="0"></el-option>
                        <el-option
                          v-for="m in table_add_menulist"
                          :key="m.IDn"
                          :label="m.Name1"
                          :value="m.IDn"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Discount')" prop="DiscountRate">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.DiscountRate" type="number" step="0.01" @change="update_table2(scope.row)" size="mini"></el-input>
                    </template>
                  </el-table-column>
                  <!-- Lisa add start  -->
                  <!-- <el-table-column :label="$t('Width')" prop="Width">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.Width" @change="update_table2(scope.row)" size="mini"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Height')" prop="Height">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.Height" @change="update_table2(scope.row)" size="mini"></el-input>
                    </template>
                  </el-table-column> -->
                  <el-table-column :label="$t('Service Charge')" prop="ServiceChargeAmount">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.ServiceChargeAmount" type="number" step="0.01" @change="update_table2(scope.row)" size="mini"></el-input>
                    </template>
                    <!-- <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.ServiceChargeIDn"
                        @change="update_table2(scope.row)"
                        size="mini"
                      >
                        <el-option :label="$t('No')" value="0"></el-option>
                        <el-option :label="$t('Yes')" value="1"></el-option>
                      </el-select>
                    </template> -->
                  </el-table-column>
                  <el-table-column :label="$t('Font Size')" prop="FontSize">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.FontSize"
                        @change="update_table2(scope.row)"
                        size="mini"
                      >
                        <el-option
                          v-for="(fs,idx) in fontsizelist"
                          :key="idx"
                          :label="fs.text"
                          :value="fs.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Font Weight')" prop="FontWeight">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.FontWeight"
                        @change="update_table2(scope.row)"
                        size="mini"
                      >
                        <el-option
                          v-for="(fw,idx) in fontweightlist"
                          :key="idx"
                          :label="fw.text"
                          :value="fw.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <!-- Lisa add end -->

                  <!-- <el-table-column :label="$t('Table Color')">
                    <template slot-scope="scope">
                      <el-color-picker
                        size="small"
                        v-model="scope.row.TableColor"
                        @change="update_table2(scope.row)"
                        color-format="hex"
                      ></el-color-picker>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="">
                    <template slot="header">
                      <i
                        @click="add_table2"
                        class="el-icon-circle-plus"
                        style="color: #409eff"
                      ></i>
                    </template>
                    <template slot-scope="scope">
                      <i
                        @click="removetable($event, scope.row)"
                        class="el-icon-remove"
                        style="color: #bd4545"
                      ></i>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </b-card>
        </el-tab-pane>
        <el-tab-pane :label="$t('Area')" name="floor">
          <b-card>
            <el-row v-if="fullfloorlist" :gutter="10">
              <el-col :span="24">
                <el-table
                  ref="floorlist"
                  stripe
                  style="width: 100%;"
                  class="table-responsive table"
                  header-row-class-name="thead-light"
                  :data="fullfloorlist"
                >
                  <el-table-column :min-width="30" :label="$t('Store')" prop="LcoationID">
                    <template slot-scope="scope">
                      <b-form-select
                        class="form-control"
                        v-model="scope.row.LocationID"
                        :disabled="scope.row.Status!=1"
                        size="sm"
                      >
                        <option
                          v-for="st in storelist"
                          :key="st.LocationID"
                          :value="st.LocationID"
                        >
                          {{ st.LocationName }}
                        </option>
                      </b-form-select>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" :label="$t('Name')">
                    <template slot-scope="scope">
                      <b-form-input
                        size="sm"
                        v-model="scope.row.Name"
                        :disabled="scope.row.Status!=1"
                      ></b-form-input>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Image')">
                    <template slot-scope="scope">
                      <el-row :gutter="20">
                        <el-col :span="18" v-if="scope.row.UseImage">
                          <b-form-file
                            size="sm"
                            accept=".jpg, .png, .gif"
                            v-model="scope.row.file1"
                            :state="Boolean(scope.row.file1)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                          ></b-form-file>
                        </el-col>
                        <el-col :span="18" v-else>
                          <i class="el-icon-close"></i>
                        </el-col>
                        <el-col :span="6">
                          <el-switch
                            v-model="scope.row.UseImage"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#bd4545">
                          </el-switch>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="30" label="">
                    <template slot="header">
                      <i
                        @click="add_floor"
                        class="el-icon-circle-plus"
                        style="color: #409eff"
                      ></i>
                    </template>
                    <template slot-scope="scope">
                      <el-row :guter="10">
                        <el-col :span="12">
                          <b-button v-if="scope.row.IDn" size="sm" @click="update_floor2(scope.row)" variant="success">{{ $t("Update") }}</b-button>
                          <b-button v-else size="sm" @click="update_floor2(scope.row)" variant="success">{{ $t("Add") }}</b-button>
                        </el-col>
                        <el-col :span="6">
                          <i
                            v-if="scope.row.Status==1"
                            @click="update_floor_status(scope.row, 0)"
                            class="el-icon-remove"
                            style="color: #bd4545"
                          ></i>
                          <i
                            v-else
                            @click="update_floor_status(scope.row, 1)"
                            class="el-icon-success"
                            style="color: #13ce66"
                          ></i>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </b-card>
          <b-row v-if="fullfloorlist" :gutter="10">
            <b-col md="4" v-for="floor in fullfloorlist" :key="floor.IDn">
              <b-card v-if="floor.UseImage" 
                :title="floor.Name + ' ( ' + get_store_name(floor) + ' ) '"
                :img-src="imageUrl + floor.Image"
                img-bottom
                class="mt-3"
                ></b-card>
            </b-col>
          </b-row>
        </el-tab-pane>
        <el-tab-pane lazy :label="$t('Table Position')" name="tableposition">
          <el-row :gutter="10">
            <el-col :span="2" class="text-right mt-1">
              {{ $t('Store')}}
            </el-col>
            <el-col :span="4">
              <el-select
                v-model="cur_LocationID"
                size="mini"
                @change="change_store"
              >
                <el-option
                  v-for="st in storelist"
                  :key="st.LocationID"
                  :label="st.LocationName"
                  :value="st.LocationID"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col v-if="cur_floorIDn" :span="2" class="text-right mt-1">
              {{ $t('Area') }}
            </el-col>
            <el-col v-if="cur_floorIDn" :span="4">
              <el-select
                v-model="cur_floorIDn"
                size="mini"
                @change="change_floor2"
              >
                <el-option
                  v-for="fl in floorlist"
                  :key="fl.IDn"
                  :label="fl.Name"
                  :value="fl.IDn"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <b-card v-if="tablelist">
            <b-row v-if="cur_floor.IDn > 0" class="mt-4">
              <b-col cols="2" class="padding-fix">
                {{ $t("Area") }} {{ $t("Layout") }}:
              </b-col>
              <b-col cols="2" v-if="cur_table.IDn>0">
                <b-form-checkbox switch v-model="movingmode">
                  <span v-if="movingmode">{{ $t("Location") }}</span><span v-else>{{ $t("Size") }}</span>
                  <i class="el-icon-top" style="color: #409eff" ></i>
                  <i class="el-icon-bottom" style="color: #409eff" ></i>
                  <i class="el-icon-back" style="color: #409eff" ></i>
                  <i class="el-icon-right" style="color: #409eff" ></i>
                </b-form-checkbox>

              </b-col>
              <b-col cols="2" v-if="cur_table.IDn>0">
                <el-input v-model="cur_table_Name" @change="change_position()" size="mini" disabled>
                  <template #prepend>
                    Table Name
                  </template>
                </el-input>
              </b-col>
              <b-col v-if="cur_table.IDn>0" cols="1">
                <el-input v-model="cur_table_OffsetX" @change="change_position()" size="mini">
                  <template #prepend>
                    X
                  </template>
                </el-input>
              </b-col>
              <b-col v-if="cur_table.IDn>0" cols="1">
                <el-input v-model="cur_table_OffsetY" @change="change_position()" size="mini">
                  <template #prepend>
                    Y
                  </template>
                </el-input>
              </b-col>
              <b-col v-if="cur_table.IDn>0&&cur_table.TableType=='Round'" cols="1">
                <el-input v-model="cur_table_Height" @change="change_position()" size="mini">
                  <template #prepend>
                    R
                  </template>
                </el-input>
              </b-col>
              <b-col v-if="cur_table.IDn>0&&cur_table.TableType=='Square'" cols="1">
                <el-input v-model="cur_table_Width" @change="change_position()" size="mini">
                  <template #prepend>
                    W
                  </template>
                </el-input>
              </b-col>
              <b-col v-if="cur_table.IDn>0&&cur_table.TableType=='Square'" cols="1">
                <el-input v-model="cur_table_Height" @change="change_position()" size="mini">
                  <template #prepend>
                    H
                  </template>
                </el-input>
              </b-col>
            </b-row>
            <div
              :class="small_sz ? 'tablelistdiv2' : 'tablelistdiv'"
              v-bind:style="tablelistimage"
              @dragover="allowDrop($event)"
            >
              <Ktable
                v-for="(tb, idx) in tablelist"
                :style="{
                  top: tb.OffsetY / small_rate + 'px',
                  left: tb.OffsetX / small_rate + 'px',
                  fontSize: getfontsizepx(tb),
                  lineHeight: getfontsizepx(tb),
                  fontWeight: tb.FontWeight,
                  paddingTop: getPadTop(tb),
                }"
                :key="tablekeys[idx]"
                :name="tb.Name"
                :shape="tb.TableType"
                :width="tb.Width"
                :height="tb.Height"
                :color="get_empty_table_color()"
                :small="small_sz"
                :draggable="cur_table.IDn==tb.IDn"
                :class="cur_table.IDn==tb.IDn?'IamKTable':''"
                @click.native="edit_table_info($event, tb)"
                @dragstart_p="dragstart($event, tb)"
                @dragend_p="dragend($event, tb)"
                @removetable="removetable($event, tb, 1)"
              ></Ktable>
            </div>
            <label class="text-center w-100"> Size: 1024x640 </label>
          </b-card>
        </el-tab-pane>
        <el-tab-pane :label="$t('Table Color')" name="tablecolor">
          <b-card>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-table
                  stripe
                  style="width: 100%;"
                  class="table-responsive table"
                  header-row-class-name="thead-light"
                  :data="table_colors"
                >
                  <el-table-column :min-width="30" :label="$t('Name')" prop="ShowName">
                    <template slot-scope="scope">
                      {{ $t(scope.row.ShowName) }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Table Color')">
                    <template slot-scope="scope">
                      <el-color-picker
                        size="small"
                        v-model="scope.row.Color"
                        @change="update_table_color(scope.row)"
                        color-format="hex"
                      ></el-color-picker>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </b-card>
        </el-tab-pane>
      </el-tabs>
    </b-container>
    <b-modal id="table-edit" modal-footer="false">
      <template #modal-header="">
        <div class="text-center w-100">{{ $t("Table") }}</div>
      </template>
      <template #default="">
        <b-form inline>
          <label class="w-25" for="input-floor-name">{{ $t("Color") }}</label>
          <el-color-picker
            v-model="movingcolor"
            size="mini"
            color-format="hex"
          ></el-color-picker>
        </b-form>
      </template>
      <template #modal-footer="">
        <button class="btn btn-primary mr-6" @click="hide_table_info">
          {{ $t("Cancel") }}
        </button>
        <button class="btn btn-primary mr-6" @click="change_table_info()">
          {{ $t("Update") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Row,
  Col,
  Switch,
  Tabs,
  TabPane,
  Select,
  Option,
  Dialog,
  ColorPicker,
} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Ktable from "../Ktable";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Switch.name]: Switch,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
    [ColorPicker.name]: ColorPicker,
    [Dialog.name]: Dialog,
    Ktable,
  },
  data() {
    return {
      tab_name: "table",

      dismessage: '',
      countdown: 0,

      table_add_floorlist: false,
      table_add_menulist: [],

      small_sz: false,
      small_rate: 1,
      storelist: null,
      menulist: [],
      cur_LocationID: 0,
      cur_floorIDn: 0,
      floorlist: null,
      fullfloorlist: null,
      cur_floor: {
        IDn: 0,
        Name: "",
        Image: "",
        Status: 0,
        UseImage: 0,
        Width: 0,
        Height: 0,
      },
      new_floor: {
        IDn: 0,
        Name: "",
        LocationID: "",
        Image: "",
        file1: null,
        Status: 1,
        UseImage: 1,
        Width: 1024,
        Height: 640,
      },

      drag_status: "",
      tabledivclass: "s2",
      tablespanclass: "ss2",
      tablelist: null,
      tabletimer: null,
      tablekeys: [],
      table_shapes: [],
      table_types: [],
      movingtable: {},
      movingtable_idx: 0,
      movingmode: true,
      movingevt: {},
      movingleft: 0,
      movingtop: 0,
      movingwidth: 0,
      movingheight: 0,
      movingcolor: "",
      cur_table_Name: "",
      cur_table_OffsetX: 60,
      cur_table_OffsetY: 60,
      cur_table_Width: 60,
      cur_table_Height: 60,
      currpage: "list",
      cur_table: {
        IDn: 0,
        LocationID: "",
        Floor: "",
        Name: "",
        TableType: "Square",
        TableType2: "Dine",
        Guest: 4,
        SortOrder: 999,
        OffsetX: 0,
        OffsetY: 0,
        Width: 60,
        Height: 60,
        TableColor: "#c0c0c0",
        Status: 1,
        Data: "",
        MenuIDn: 0,
        DiscountRate: 0,
        // Lisa add start
        FontSize: 5,
        FontWeight: 400,
        // Lisa add end
      },
      cur_opt: null,
      new_table: {
        IDn: 0,
        LocationID: "",
        Floor: "",
        Name: "",
        TableType: "Square",
        TableType2: "Dine",
        Guest: 4,
        SortOrder: 999,
        OffsetX: 0,
        OffsetY: 0,
        Width: 60,
        Height: 60,
        TableColor: "#c0c0c0",
        Status: 1,
        Data: "",
        MenuIDn: 0,
        DiscountRate: 0,
        // Lisa add start
        FontSize: 5,
        FontWeight: 400,
        // Lisa add end
      },
      table_status: [],
      search: {
        LocationID: "",
        Floor: "",
        Status: "",
      },

      table_colors: null,

      token: "",
      user_id: 0,

      show_floor_edit: false,

      movingEditTableLeft: 0,
      movingEditTableTop: 0,
      delayMovingEditTable: 0,
      // lisa add start
      fontsizelist:[
        {value: 1, text:'1'}, {value: 2, text:'2'}, {value: 3, text:'3'},
        {value: 4, text:'4'}, {value:5, text:'5'}, {value:6, text:'6'},
        {value: 7, text:'7'}
      ],
      fontweightlist:[
        {value: 400, text:'normal'}, {value: 500, text:'normal+'}, {value:600, text:'bold'},
        {value: 700, text:'bold+'}, {value: 800, text:'bolder'}
      ],
      // lisa add end

    };
  },
  methods: {
    get_empty_table_color() {
      if (this.table_colors) {
        for (var i=0; i<this.table_colors.length; i++) {
          if (this.table_colors[i].Name == "EmptyTable") {
            return this.table_colors[i].Color;
          }
        }
      }
      return "#c0c0c0";
    },
    update_table_color(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Color", row.Color);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/update_color",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = 'Table Color Data save Successfully';
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_store_name(row) {
      for (var i=0; i<this.storelist.length; i++) {
        if (row.LocationID == this.storelist[i].LocationID) {
          return this.storelist[i].LocationName;
        }
      }
      return "N/A";
    },
    get_floor_name(row) {
      if (!this.floorlist) return "";

      for (var i=0; i<this.floorlist.length; i++) {
        if (row.Floor == this.floorlist[i].IDn) {
          return this.floorlist[i].Name;
        }
      }
      return "N/A";
    },
    floorspan({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2) {
        return { rowspan: 1, colspan: 2 };
      } else if (columnIndex === 3) {
        return { rowspan: 1, colspan: 0 };
      }
    },
    tab_clicked(tab, event) {
      if (event) {
        event.preventDefault();
      }
      this.fullfloorlist = null;
      this.cur_table.IDn = -1;   // Remove current table
      if (tab.name == "floor") {
        // Load full floor
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);

        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/Floor/search",
          data: bodyFormData,
        })
          .then(function (response) {
            if (response.status == "200") {
              var rt = response.data;
              if (rt.status == 0) {
                that.fullfloorlist = rt.data.floors;
                if (that.fullfloorlist.length > 0) {
                  for (var i=0; i<that.fullfloorlist.length; i++) {
                    that.fullfloorlist[i]['file1'] = null;
                    that.fullfloorlist[i]['UseImage'] = parseInt(that.fullfloorlist[i]['UseImage']);
                  }
                }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
              } else if (rt.message != undefined) {
                alert(rt.message);
              }
            } else {
              that.$router.push("/login");
            }
          })
          .catch(function (error) {
            // console.log(error);
            that.$router.push("/login");
          });
      } else if (tab.name == "tableposition") {
        this.get_cur_table_list();
      } else {
        this.change_store();
      }
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      if (
        column &&
        (column.label == "Tax Type" ||
          column.label == "Printer" ||
          column.label == "PriceA")
      ) {
        // Skip some columns by Label
        return;
      }
      // this.currpage = "edit";
      // this.cur_product = JSON.parse(JSON.stringify(row));
      // this.btnselectchange();
      // this.cur_opt = "update";
      // this.next_row = this.find_next_product(row);
    },
    filter_menu(LocationID) {
      this.table_add_menulist = [];
      for (var i=0; i<this.menulist.length; i++) {
        if (this.menulist[i].LocationID.search(LocationID)>=0) {
          this.table_add_menulist.push(this.menulist[i]);
        }
      }
    },
    change_table_add_store(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", row.LocationID);

      this.table_add_floorlist = null;
      this.filter_menu(row.LocationID);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Floor/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.table_add_floorlist = rt.data.floors;
              if (rt.data.floors.length > 0) {
                row.Floor = that.table_add_floorlist[0].IDn;
              } else {
                row.Floor = 0;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_store() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.cur_LocationID);

      this.floorlist = null;
      this.tablelist = null;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Floor/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.floorlist = rt.data.floors;
              if (rt.data.floors.length > 0) {
                that.cur_floor = JSON.parse(JSON.stringify(that.floorlist[0]));
                that.cur_floorIDn = that.cur_floor.IDn;
                that.get_cur_table_list();
              } else {
                that.cur_floor = JSON.parse(JSON.stringify(that.new_floor));
                that.cur_floorIDn = 0;
                that.tablelist = null;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_floor2() {
      var floor = null;
      if (!this.floorlist) return;

      for (var i=0; i<this.floorlist.length; i++) {
        if (this.floorlist[i].IDn == this.cur_floorIDn) {
          floor = JSON.parse(JSON.stringify(this.floorlist[i]));
          break;
        }
      }
      if (!floor) {
        this.cur_floor = JSON.parse(JSON.stringify(that.new_floor));
        alter("Can't find floor informatation");
        return;
      }
      this.cur_floor = floor;
      this.get_cur_table_list();
    },
    async add_table2() {
      this.new_table.LocationID = this.cur_LocationID;
      this.table_add_floorlist = this.floorlist;
      this.new_table.Floor = this.cur_floorIDn;
      this.tablelist.unshift(JSON.parse(JSON.stringify(this.new_table)));
    },

    show_floor_edit_now() {
      this.show_floor_edit = !this.show_floor_edit;
    },
    tablekeys_gen() {
      this.tablekeys = [];
      for (var i = 0; i < this.tablelist.length; i++) {
        this.tablekeys.push(this.tablelist[i].IDn);
      }
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    async add_floor() {
      this.new_floor.LocationID = this.cur_LocationID;
      this.new_floor.Image = "/image/PkFloorDefault.jpg";   // Lisa add
      this.fullfloorlist.unshift(JSON.parse(JSON.stringify(this.new_floor)));
    },
    async update_floor2(row) {
      if (row.Name == "") {
        alert("Please input Area Name");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (row.IDn) {
        bodyFormData.append("opt", "update");
      } else {
        bodyFormData.append("opt", "add");
      }
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("LocationID", row.LocationID);
      bodyFormData.append("Name", row.Name);
      bodyFormData.append("UseImage", row.UseImage);
      bodyFormData.append("Width", row.Width);
      bodyFormData.append("Height", row.Height);
      bodyFormData.append("Status", row.Status);
      if (row.file1) {
        bodyFormData.append("file1", row.file1);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Floor/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
              if (rt.data.floors) {
                that.fullfloorlist = rt.data.floors;
                if (that.fullfloorlist.length > 0) {
                  for (var i=0; i<that.fullfloorlist.length; i++) {
                    that.fullfloorlist[i]['file1'] = null;
                    that.fullfloorlist[i]['UseImage'] = parseInt(that.fullfloorlist[i]['UseImage']);
                  }
                }
              }
              that.dismessage = 'Area Data save Successfully';
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_floor_status(row, status) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Status", status);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Floor/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.floors) {
                that.fullfloorlist = rt.data.floors;
                if (that.fullfloorlist.length > 0) {
                  for (var i=0; i<that.fullfloorlist.length; i++) {
                    that.fullfloorlist[i]['file1'] = null;
                    that.fullfloorlist[i]['UseImage'] = parseInt(that.fullfloorlist[i]['UseImage']);
                  }
                }
              }
              that.dismessage = 'Area Data save Successfully';
              that.countdown = 3;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_table_info() {
      this.movingtable.TableColor = this.movingcolor;
      if (this.tablekeys[this.movingtable_idx] > 100000) {
        this.tablekeys[this.movingtable_idx] -= 100000;
      } else {
        this.tablekeys[this.movingtable_idx] += 100000;
      }
      this.$bvModal.hide("table-edit");
      this.update_table_xy(this.movingtable);
    },
    hide_table_info() {
      this.$bvModal.hide("table-edit");
    },
    keyspressed(ev) {
      if (this.tab_name == "tableposition") {
        if (this.movingtable.IDn == this.cur_table.IDn) {
          var doing = false;
          if (this.movingmode) {
            if (ev.key == 'ArrowUp') {
              this.cur_table.OffsetY -= 1;
              this.movingtable.OffsetY -= 1;
              this.cur_table_OffsetY -= 1;
              doing = true;
            } else if (ev.key == 'ArrowDown') {
              this.cur_table.OffsetY += 1;
              this.movingtable.OffsetY += 1;
              this.cur_table_OffsetY += 1;
              doing = true;
            } else if (ev.key == 'ArrowRight') {
              this.cur_table.OffsetX += 1;
              this.movingtable.OffsetX += 1;
              this.cur_table_OffsetX += 1;
              doing = true;
            } else if (ev.key == 'ArrowLeft') {
              this.cur_table.OffsetX -= 1;
              this.movingtable.OffsetX -= 1;
              this.cur_table_OffsetX -= 1;
              doing = true;
            }
          } else {
            if (ev.key == 'ArrowUp') {
              this.cur_table.Height -= 1;
              this.movingtable.Height -= 1;
              this.cur_table_Height -= 1;
              doing = true;
            } else if (ev.key == 'ArrowDown') {
              this.cur_table.Height += 1;
              this.movingtable.Height += 1;
              this.cur_table_Height += 1;
              doing = true;
            } else if (ev.key == 'ArrowRight') {
              this.cur_table.Width += 1;
              this.movingtable.Width += 1;
              this.cur_table_Width += 1;
              doing = true;
            } else if (ev.key == 'ArrowLeft') {
              this.cur_table.Width -= 1;
              this.movingtable.Width -= 1;
              this.cur_table_Width -= 1;
              doing = true;
            }
          }
          if (doing) {
            this.update_table_xy(this.cur_table);
            this.change_listed_table(this.cur_table);
          }
        }
      }
    },
    edit_table_info(ev, tb) {
      this.movingtable = tb;
      this.cur_table = tb;
      this.cur_table_Name = tb.Name;
      this.cur_table_OffsetX = tb.OffsetX;
      this.cur_table_OffsetY = tb.OffsetY;
      this.cur_table_Width = tb.Width;
      this.cur_table_Height = tb.Height;
      if (this.drag_status == "remove") {
        return;
      }
      // this.movingmode = true;
      // this.dragstart(ev, tb);
      // this.$bvModal.show("table-edit");
    },
    delay_fire_end(ev) {
      clearTimeout(this.tabletimer);
      this.tabletimer = setTimeout(this.delaydragend, 500);
    },
    markStartPoint(ev) {
      //console.log("allEditMove", ev );
      this.movingEditTableLeft = ev.offsetX;
      this.movingEditTableTop = ev.offsetY;
    },
    allowEditMove(ev) {
      var editWindow = document.getElementById("editWindow");
      var left = parseFloat(
        editWindow.style.left.substring(0, editWindow.style.left.length - 2)
      );
      var x = left + ev.offsetX - this.movingEditTableLeft;
      if (x < 4) {
        x = 4;
      } else if (x > 814) {
        x = 814;
      }
      x = x + "px";
      editWindow.style.left = x;
      /*
      var top =parseFloat( editWindow.style.top.substring(0, editWindow.style.top.length - 2));
      var y = top - ev.offsetY + this.movingEditTableTop;

      var space = 640 - 434;
      if (y < 2) {
        y = 2;
      } else if (y > space) {
        y = space -1;
      }
      y = y  + "px";
      editWindow.style.top = y;
      console.log("y:", y, top , ev.offsetY , this.movingEditTableTop);
      */
    },
    allowDrop(ev) {
      if (!this.movingmode) {
        var offx = ev.screenX - this.movingleft;
        var offy = ev.screenY - this.movingtop;
        offx *= this.small_rate;
        offy *= this.small_rate;
        var neww = this.movingwidth + offx;
        var newh = this.movingheight + offy;

        if (neww > 320) neww = 320;
        if (neww < 20) neww = 20;
        if (newh > 320) newh = 320;
        if (newh < 20) newh = 20;

        if (this.movingtable.TableType == "Round") {
          neww = newh;
        }

        this.movingtable.Width = neww;
        this.movingtable.Height = newh;
        this.cur_table.Width = neww;
        this.cur_table.Height = newh;
        this.cur_table_Width = neww;
        this.cur_table_Height = newh;
        if (this.tablekeys[this.movingtable_idx] > 100000) {
          this.tablekeys[this.movingtable_idx] -= 100000;
        } else {
          this.tablekeys[this.movingtable_idx] += 100000;
        }
        this.delay_fire_end();
      }
    },
    dragstart(ev, tb) {
      if (!this.movingmode) {
        let img = new Image();
        img.src = this.imageUrl + "/image/Adjust.png";
        // img.style.height = '20px';
        // img.style.width = '20px';
        ev.dataTransfer.setDragImage(img, 10, 10);
      }
      this.drag_status = "start";
      this.movingevt = ev;
      this.movingtable = tb;
      this.movingleft = ev.screenX;
      this.movingtop = ev.screenY;
      this.movingwidth = tb.Width;
      this.movingheight = tb.Height;
      this.cur_table_Width = tb.Width;
      this.cur_table_Height = tb.Height;
      this.movingcolor = tb.TableColor;
      for (var i = 0; i < this.tablekeys.length; i++) {
        if (this.tablekeys[i] == tb.IDn) {
          this.movingtable_idx = i;
          break;
        }
      }
    },
    delaydragend() {
      if (this.drag_status != "end") {
        this.drag_status = "end";
        this.update_table_xy(this.movingtable);
      }
    },
    dragend(ev, tb) {
      ev.preventDefault();
      this.drag_status = "end";
      if (this.movingmode) {
        var offx = ev.screenX - this.movingleft;
        var offy = ev.screenY - this.movingtop;
        var offx2 = offx * this.small_rate;
        var offy2 = offy * this.small_rate;
        var nowx = parseInt(this.movingevt.target.style.left) || 0;
        var nowy = parseInt(this.movingevt.target.style.top) || 0;

        var newx = nowx + offx;
        var newy = nowy + offy;
        if (newx < 0) newx = 0;
        if (newy < 0) newy = 0;
        if (newx * this.small_rate + tb.Width > 1024)
          newx = (1024 - tb.Width) / this.small_rate;
        if (newy * this.small_rate + tb.Height > 640)
          newy = (640 - tb.Height) / this.small_rate;

        this.movingevt.target.style.left = newx + "px";

        this.movingevt.target.style.top = newy + "px";
        this.movingtable.OffsetX = newx * this.small_rate;
        this.movingtable.OffsetY = newy * this.small_rate;
        this.cur_table.OffsetX = this.movingtable.OffsetX;
        this.cur_table.OffsetY = this.movingtable.OffsetY;
        this.cur_table_OffsetX = this.movingtable.OffsetX;
        this.cur_table_OffsetY = this.movingtable.OffsetY;
      }
      this.update_table_xy(this.movingtable);
    },
    change_position() {
      this.drag_status = "end";
      this.cur_table.Width = parseInt(this.cur_table_Width);
      this.cur_table.Height = parseInt(this.cur_table_Height);
      this.cur_table.OffsetX = parseInt(this.cur_table.OffsetX);
      this.cur_table.OffsetY = parseInt(this.cur_table.OffsetY);
      this.movingtable.Width = parseInt(this.cur_table_Width);
      this.movingtable.Height = parseInt(this.cur_table_Height);
      this.movingtable.OffsetX = parseInt(this.cur_table_OffsetX);
      this.movingtable.OffsetY = parseInt(this.cur_table_OffsetY);
      if (this.cur_table.TableType == "Round") {
        this.cur_table.Width = this.cur_table_Height;
      }
      this.update_table_xy(this.cur_table);
      this.change_listed_table(this.cur_table);
    },
    change_listed_table(tb) {
      for (var i = 0; i < this.tablelist.length; i++) {
        if (this.tablelist[i].IDn == tb.IDn) {
          this.tablelist[i].Width = tb.Width;
          this.tablelist[i].Height = tb.Height;
          this.tablelist[i].OffsetX = tb.OffsetX;
          this.tablelist[i].OffsetY = tb.OffsetY;
          if (this.tablekeys[i] > 100000) {
            this.tablekeys[i] -= 100000;
          } else {
            this.tablekeys[i] += 100000;
          }
        }
      }
    },
    getfontsizepx(tb){
      var minfontsize = 10;
      var progression = 0.106;
      var minratio = 0.16; // Font size 1
      var fontpx =  parseInt(tb.Height) * (minratio + (parseInt(tb.FontSize) -1) * progression);
      if(fontpx < minfontsize) {
        fontpx = minfontsize;
      }
      var returnfont = fontpx  + "px"; 

      return returnfont;
    },
    getPadTop(tb){
      var minfontsize = 10;
      var progression = 0.106;
      var minratio = 0.16; // Font size 1
      var fontpx =  parseInt(tb.Height) * (minratio + (parseInt(tb.FontSize) -1) * progression);
      if(fontpx < minfontsize) {
        fontpx = minfontsize;
      }

      if (parseInt(tb.Height) < fontpx) {
        return "0px";
      }  
      return parseInt((parseInt(tb.Height) - fontpx) / 2) + "px";
    },
    async removetable(ev, tb) {
      ev.preventDefault();
      this.drag_status = "remove";
      this.movingmode = true;
      // let findtype = false;
      // for (var i=0; i<this.table_types.length; i++) {
      //   if (tb.TableType2 == this.table_types[i]) {
      //     findtype = true;
      //     break;
      //   }
      // }
      // if (!findtype) {
      //   alert("The Table can't be deleted!");
      //   return;
      // }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "disable");
      bodyFormData.append("IDn", tb.IDn);
      bodyFormData.append("Status", 2);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
              that.tablelist = that.format_table_data(rt.data.tables);
              that.tablekeys_gen();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          that.drag_status = "";
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
          that.drag_status = "";
        });
    },
    new_table_type() {
      if (this.cur_table.TableType == "Round") {
        this.tabledivclass = "r2";
        this.tablespanclass = "sr2";
        // } else if (this.cur_table.TableType == "H-Rectangle") {
        //   this.tabledivclass = "h";
        //   this.tablespanclass = "sh";
        // } else if (this.cur_table.TableType == "W-Rectangle") {
        //   this.tabledivclass = "w";
        //   this.tablespanclass = "sw";
      } else {
        this.tabledivclass = "s2";
        this.tablespanclass = "ss2";
      }
    },
    async update_table2(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (row.IDn) {
        bodyFormData.append("opt", "update");
      } else {
        bodyFormData.append("opt", "add");
        bodyFormData.append("LocationID", row.LocationID);
        bodyFormData.append("Floor", row.Floor);
      }
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Name", row.Name);
      bodyFormData.append("TableType", row.TableType);
      bodyFormData.append("TableType2", row.TableType2);
      bodyFormData.append("Guest", row.Guest);
      bodyFormData.append("MenuIDn", row.MenuIDn);
      bodyFormData.append("DiscountRate", row.DiscountRate);
      bodyFormData.append("TableColor", row.TableColor);
      bodyFormData.append("ServiceChargeAmount", row.ServiceChargeAmount);
      bodyFormData.append("FontSize", row.FontSize);
      bodyFormData.append("FontWeight", row.FontWeight);
      if (row.TableType =="Round") {
        bodyFormData.append("Width", parseInt(row.Height));
        bodyFormData.append("Height", parseInt(row.Height));
      } else {
        bodyFormData.append("Width", parseInt(row.Width));
        bodyFormData.append("Height", parseInt(row.Height));
      }
      bodyFormData.append("OffsetX", parseInt(row.OffsetX));
      bodyFormData.append("OffsetY", parseInt(row.OffsetY));
      // Lisa add end

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.IDn) {
                row.IDn = rt.data.IDn;
              }
              // that.$router.push("/store");
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_table_xy(tb) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      bodyFormData.append("IDn", tb.IDn);
      bodyFormData.append("OffsetX", tb.OffsetX);
      bodyFormData.append("OffsetY", tb.OffsetY);
      bodyFormData.append("Width", tb.Width);
      bodyFormData.append("Height", tb.Height);
      bodyFormData.append("TableColor", tb.TableColor);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event, is_copy) {
      if (event) {
        event.preventDefault();
      }
      if (this.cur_table.Name == "") {
        return;
      }
      if (this.cur_table.Guest == "") {
        return;
      }
      if (this.cur_table.IDn < 0) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (is_copy) {
        this.cur_table.IDn = 0;
        this.cur_table.OffsetX = 0;
        this.cur_table.OffsetY = 0;
        bodyFormData.append("opt", "add");
      } else if (this.cur_table.IDn) {
        bodyFormData.append("opt", "update");
      } else {
        bodyFormData.append("opt", "add");
      }
      bodyFormData.append("IDn", this.cur_table.IDn);
      bodyFormData.append("LocationID", this.cur_LocationID);
      bodyFormData.append("Floor", this.cur_floor.IDn);
      bodyFormData.append("Name", this.cur_table.Name);
      bodyFormData.append("TableType", this.cur_table.TableType);
      bodyFormData.append("TableType2", this.cur_table.TableType2);
      bodyFormData.append("Guest", this.cur_table.Guest);
      bodyFormData.append("SortOrder", this.cur_table.SortOrder);
      bodyFormData.append("OffsetX", this.cur_table.OffsetX);
      bodyFormData.append("OffsetY", this.cur_table.OffsetY);
      bodyFormData.append("Width", this.cur_table.Width);
      bodyFormData.append("Height", this.cur_table.Height);
      bodyFormData.append("TableColor", this.cur_table.TableColor);
      bodyFormData.append("MenuIDn", this.cur_table.MenuIDn);
      bodyFormData.append("DiscountRate", this.cur_table.DiscountRate);
      bodyFormData.append("Status", 1);
      // Lisa add start
      bodyFormData.append("FontSize", this.cur_table.FontSize);
      bodyFormData.append("FontWeight", this.cur_table.FontWeight);
      // Lisa add end

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
              that.cur_table = that.new_table;
              that.get_cur_table_list();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    format_table_data(tables) {
      for (var i = 0; i < tables.length; i++) {
        tables[i].IDn = parseInt(tables[i].IDn);
        tables[i].Height = parseInt(tables[i].Height);
        tables[i].Width = parseInt(tables[i].Width);
        tables[i].OffsetX = parseInt(tables[i].OffsetX);
        tables[i].OffsetY = parseInt(tables[i].OffsetY);
        tables[i].SortOrder = parseInt(tables[i].SortOrder);
        tables[i].Guest = parseInt(tables[i].Guest);
        tables[i].FontWeight = parseInt(tables[i].FontWeight);
        tables[i].FontSize = parseInt(tables[i].FontSize);
      }
      return tables;
    },
    async get_cur_table_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.cur_LocationID);
      bodyFormData.append("Floor", this.cur_floorIDn);

      this.tablelist = null;
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.tablelist = rt.data.tables;
              that.tablelist = that.format_table_data(rt.data.tables);
              that.filter_menu(that.cur_LocationID);
              that.tablekeys_gen();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_table_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.search.LocationID);
      bodyFormData.append("Floor", this.search.Floor);
      bodyFormData.append("Status", this.search.Status);
      this.tablelist = null;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Table",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              that.menulist = rt.data.menus;
              if (that.storelist.length <= 0) {
                that.$router.push("/store");
                return;
              }
              that.cur_LocationID = that.storelist[0].LocationID;
              that.floorlist = rt.data.floors;
              that.table_shapes = rt.data.table_shapes;
              that.table_types = rt.data.table_types;
              that.table_status = rt.data.table_status;
              that.table_colors = rt.data.table_colors;
              if (that.floorlist.length > 0) {
                that.cur_floor = JSON.parse(JSON.stringify(that.floorlist[0]));
                that.cur_floorIDn = that.cur_floor.IDn;
                that.tablelist = that.format_table_data(rt.data.tables);
                that.filter_menu(that.cur_LocationID);
                that.tablekeys_gen();
              } else {
                that.cur_floor = JSON.parse(JSON.stringify(that.new_floor));
                that.cur_floorIDn = 0;
                that.tablelist = null;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_table_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
    document.addEventListener("keyup", this.keyspressed);
    /*
    if (window.innerWidth < 1276) {
      this.small_sz = true;
      this.small_rate = 2;
      this.tabledivclass = "s2";
      this.tablespanclass = "ss2";
    }
    */
  },
  computed: {
    imageUrl() {
      return axios.defaults.baseURL + "/Api";
    },
    tablelistimage() {
      if (this.cur_floor.Image == "" || !this.cur_floor.UseImage) {
        return {};
      } else {
        var imgurl = axios.defaults.baseURL + "/Api" + this.cur_floor.Image;
        return {
          backgroundImage: `url(${imgurl})`,
          "background-size": "1024px 640px",
          "background-repeat": "no-repeat",
          "background-position": "center center",
        };
      }
    },
  },
  watch: {
    // cur_floor: function (oldval, newval) {
    //   if (newval.IDn == 0) {
    //     // Do nothing when it is a new floor
    //     return;
    //   }
    //   var bodyFormData = new FormData();
    //   bodyFormData.append("token", this.token);
    //   bodyFormData.append("user_id", this.user_id);
    //   if (oldval.IDn == newval.IDn) {
    //     bodyFormData.append("opt", "update");
    //     for (var key in newval) {
    //       if (this.cur_table[key])
    //         bodyFormData.append(key, this.cur_table[key]);
    //     }
    //     var that = this;
    //     axios({
    //       method: "post",
    //       url: "/Api/Web/Floor/edit",
    //       data: bodyFormData,
    //     })
    //       .then(function (response) {
    //         if (response.status == "200") {
    //           var rt = response.data;
    //           if (rt.status == 0) {
    //             that.floorlist = rt.data.floors;
    //             that.cur_floor = that.floorlist[0];
    //           } else if (rt.message != undefined) {
    //             alert(rt.message);
    //           }
    //         } else {
    //           this.$router.push("/login");
    //         }
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         that.$router.push("/login");
    //       });
    //   } else {
    //     bodyFormData.append("LocationID", this.cur_LocationID);
    //     bodyFormData.append("FLoorIDn", this.cur_floor.IDn);
    //     var that = this;
    //     axios({
    //       method: "post",
    //       url: "/Api/Web/Table/search",
    //       data: bodyFormData,
    //     })
    //       .then(function (response) {
    //         if (response.status == "200") {
    //           var rt = response.data;
    //           if (rt.status == 0) {
    //             that.tablelist = rt.data.tables;
    //             that.cur_table = that.new_table;
    //           } else if (rt.message != undefined) {
    //             alert(rt.message);
    //           }
    //         } else {
    //           this.$router.push("/login");
    //         }
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         that.$router.push("/login");
    //       });
    //   }
    // },
  },
};
</script>
<style>
.store-table .card .table td {
  padding: 0;
}
.store-table .custom-file-label {
  height: calc(1.8rem + 2px);
  padding: 0.2rem 2rem;
}
.store-table .custom-file-label::after {
  height: 1.8rem;
  padding: 0.2rem;
}
.store-table .custom-file {
  height: calc(1.8rem + 2px);
  padding: 0.2rem 2rem;
}
.store-table .custom-file .label {
  height: calc(1.8rem + 2px);
  margin: 0;
}
.new-table-img {
  height: 50px;
}
.table-list-group {
  max-width: 184px;
}
.tablelistdiv {
  position: relative;
  width: 1000px;
  height: 640px;
  border: 1px solid grey;
}
.table-margin-top {
  margin-top: 0.8rem;
}
.save-all-margin-top {
  margin-top: 100px;
}
.table-content {
  padding-top: 30px !important;
}
.form-group-bottom-margin {
  margin-bottom: 0em;
}
.s {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 60px;
  height: 60px;
  border: solid 1px black;
  border-radius: 5px;
}
.s2 {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 10px;
  width: 40px;
  height: 40px;
  border: solid 1px black;
  border-radius: 5px;
}
.ss {
  display: inline-block;
  margin-top: 14px;
}
.ss2 {
  display: inline-block;
  margin-top: 7px;
}
.w {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 80px;
  height: 60px;
  border: solid 1px black;
  border-radius: 5px;
}
.w2 {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 10px;
  width: 40px;
  height: 30px;
  border: solid 1px black;
  border-radius: 5px;
}
.sw {
  display: inline-block;
  margin-top: 14px;
}
.sw2 {
  display: inline-block;
  margin-top: 7px;
}
.h {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 60px;
  height: 80px;
  border: solid 1px black;
  border-radius: 5px;
}
.h2 {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 10px;
  width: 30px;
  height: 40px;
  border: solid 1px black;
  border-radius: 5px;
}
.sh {
  display: inline-block;
  margin-top: 24px;
}
.sh2 {
  display: inline-block;
  margin-top: 12px;
}
.r {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 20px;
  width: 70px;
  height: 70px;
  border: solid 1px black;
  border-radius: 50%;
}
.r2 {
  position: absolute;
  background-color: #c0c0c0;
  text-align: center;
  font-size: 10px;
  width: 35px;
  height: 35px;
  border: solid 1px black;
  border-radius: 50%;
}
.sr {
  display: inline-block;
  margin-top: 18px;
}
.sr2 {
  display: inline-block;
  margin-top: 9px;
}
.ktable-font {
  font-size: 0.8rem;
  padding: 0.25rem 2px;
}

.ktable-font fieldset {
  margin: 0;
}
.content {
  padding-top: 60px;
}

.padding-fix {
  padding-top: 0.625rem;
}
.footer {
  display: none;
}
#input-table-people {
  margin-top: 0.6rem;
}
.show_alert {
  position: absolute;
  top: 2rem;
  width: 100%;
  z-index: 100;
}
.el-input__inner {
  padding:0 5px;
}
.el-input-group__prepend {
  padding:0 4px;
}
.IamKTable{
  box-shadow: 4px 4px #888888;
}
</style>
